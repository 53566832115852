<!--
 * @Author: 蒋威
 * @Date: 2022-05-23 08:55:51
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-26 17:25:36
 * @Description: 产品与服务 
-->
<template>
  <div>
    <Breadcrumb ref="breadcrumbRefs" />
    <!-- 详情 -->
    <RichText ref="productRichText" :id="$route.query.id" />



  </div>
</template>

<script>
import RichText from '@/components/RichText.vue'
export default {
  components: {
    RichText,
  },
  data() {
    return {
      bannerList: [
      ],
    }
  },
  methods: {
    changeMenu() {},
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.breadcrumbRefs && this.$refs.breadcrumbRefs.setMenu()
      this.$refs.productRichText && this.$refs.productRichText.init()
    })
  },
  created() {},
}
</script>

<style lang="scss" scoped>
</style>